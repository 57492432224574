/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React, { ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import { GlobalProvider } from './src/components/global-context';
import { WrapPageElementBrowserArgs, ShouldUpdateScrollArgs } from 'gatsby';

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs): ReactNode => (
  <GlobalProvider>
    <AnimatePresence mode='wait'>
      {element}
    </AnimatePresence>
  </GlobalProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}: ShouldUpdateScrollArgs): boolean => {

  const transitionDelay = 600;
  window.history.scrollRestoration = "manual";

  // Check if the navigation is within the same page (only parameters changed)
  if (prevRouterProps && location.pathname === prevRouterProps.location.pathname) {
    // Maintain the current scroll position for in-page navigations (e.g., filter changes)
    return false;
  }

  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    window.scrollTo(...currentPosition);
  }, transitionDelay);

  return false;
};
