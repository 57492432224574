exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-collection-cabinetry-tsx": () => import("./../../../src/templates/collection-cabinetry.tsx" /* webpackChunkName: "component---src-templates-collection-cabinetry-tsx" */),
  "component---src-templates-collection-custom-tsx": () => import("./../../../src/templates/collection-custom.tsx" /* webpackChunkName: "component---src-templates-collection-custom-tsx" */),
  "component---src-templates-collection-finishes-tsx": () => import("./../../../src/templates/collection-finishes.tsx" /* webpackChunkName: "component---src-templates-collection-finishes-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-page-custom-tsx": () => import("./../../../src/templates/page-custom.tsx" /* webpackChunkName: "component---src-templates-page-custom-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

