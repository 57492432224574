import React, { createContext, useState, useContext, useEffect, useCallback, ReactNode } from 'react';

// Define the shape of the context
interface GlobalContextProps {
  isMobile: boolean;
  showOverlay: boolean;
  handleToggleOverlay: () => void;
  handleShowOverlay: () => void;
  handleHideOverlay: () => void;
  formCollection: string | null;
  handleSetFormCollection: (slug: string) => void;
}

// Provide a default value for the context (optional)
const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

interface GlobalProviderProps {
  children: ReactNode;
}

// Provider Component
export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  // Initiate states
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [formCollection, setFormCollection] = useState<string | null>(null);

  // Check if the window width corresponds to your mobile breakpoint
  const handleCheckBp = useCallback(() => {
    const isMobileNow = window.matchMedia(`(max-width: 899px)`).matches;
    if (isMobileNow !== isMobile) {
      setIsMobile(isMobileNow);
    }
  }, [isMobile]);

  // Function to show overlay
  const handleShowOverlay = () => {
    setShowOverlay(true);
  };

  // Function to hide overlay
  const handleHideOverlay = () => {
    setShowOverlay(false);
  };

  // Function to set form collection
  const handleSetFormCollection = (slug: string) => {
    setFormCollection(slug);
  };

  // Set up event listener for resize events
  useEffect(() => {
    handleCheckBp(); // Check immediately on mount
    window.addEventListener('resize', handleCheckBp);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleCheckBp);
  }, [handleCheckBp]);

  return (
    <GlobalContext.Provider value={{ isMobile, showOverlay, handleShowOverlay, handleHideOverlay, formCollection, handleSetFormCollection }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the global state
export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalProvider');
  }
  return context;
};
